import pages from './pages-v2';

const metaData = {
  domain: 'https://www.regnio.co.jp',
  urls: {
    index: pages.index.path,
    // business: pages.business.path,
    // vision: pages.vision.path,
    products: pages.products.path,
    company: pages.company.path,
    member: pages.member.path,
    works: pages.works.path,
    contents: pages.contents.path,
    contact: pages.contact.path,
    notFound: pages.notFound.path,
  },
  title: `株式会社Regnio`,
  description: `株式会社Regnio（リグニオ）は、ハードウェア、ソフトウェア、通信、機械学習/データ分析、デザインの技術を掛け合わせリアルとデジタルを繋ぎ、老舗企業のDXを行う会社です。`,
  lang: `ja`,
  author: `株式会社Regnio`,
};

export default metaData;
