import { useCallback } from 'react';

const useJudgeServerSideRendering = () => {
  const judgeServerSideRendering = useCallback(() => {
    if (typeof window === `undefined`) {
      return true;
    }
    return false;
  }, []);

  return judgeServerSideRendering;
};

export default useJudgeServerSideRendering;
