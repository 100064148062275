const pagesV2 = {
  index: { path: '/', name: 'index', label: null, publish: true },
  DXoutside: {
    path: '/dx-system-development/',
    name: 'dx-system-development',
    label: { jp: '外側からのDX', en: 'DX System Development' },
    publish: true,
  },
  DXinside: {
    path: '/dx-hr-development/',
    name: 'dx-hr-development',
    label: { jp: '内側からのDX', en: 'DX HR Development' },
    publish: true,
  },
  products: {
    path: 'https://factory.regnio.co.jp/',
    name: 'Regnio Factory',
    label: { jp: 'Regnio Factory', en: 'Regnio Factory' },
    publish: true,
  },
  company: {
    path: '/company/',
    name: 'company',
    label: { jp: '会社情報', en: 'Company' },
    publish: true,
  },
  vision: {
    path: '/company#vision',
    name: 'vision',
    label: { jp: 'ビジョン・想い', en: 'Vision' },
    publish: true,
  },
  importantForUs: {
    path: '/company#important',
    name: 'importantForUs',
    label: { jp: 'Regnioが大切にしていること', en: 'Important for us' },
    publish: true,
  },
  member: {
    path: '/company#member',
    name: 'member',
    label: { jp: 'メンバー', en: 'Member' },
    publish: true,
  },
  works: {
    path: '/works/',
    name: 'works',
    label: { jp: '実績紹介', en: 'Works' },
    publish: true,
  },
  dxhumanIntroduce: {
    path: '/works/dx-hr-development/',
    name: 'dx-hr-development',
    label: { jp: 'DX技術者育成プログラム', en: 'DX Engineer Training Program' },
    publish: true,
  },
  contents: {
    path: '/contents/',
    name: 'contents',
    label: { jp: '読み物', en: 'Contents' },
    publish: true,
  },
  contact: {
    path: '/contact/',
    name: 'contact',
    label: { jp: 'お問い合わせ', en: 'Contact' },
    publish: true,
  },
  news: {
    path: '/news/',
    name: 'news',
    label: { jp: 'ニュース', en: 'News' },
    publish: true,
  },
  notFound: {
    path: '/404/',
    name: '404 Not found.',
    label: null,
    publish: true,
  },
};

export const pageKeys = [
  pagesV2.index,
  // pagesV2.business,
  pagesV2.vision,
  pagesV2.company,
  pagesV2.member,
  pagesV2.contents,
  pagesV2.contact,
  pagesV2.notFound,
  pagesV2.works,
];

export default pagesV2;
