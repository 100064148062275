import React from 'react';
import { Helmet } from 'react-helmet';
import metaData from '../../../../constants-v2/meta-data';

const SEOHeader = ({
  pageTitle,
  pageDescription,
  pageUrl,
  ogpImageName,
  ogpImageUrl,
}) => {
  const { lang } = metaData;
  const title =
    pageTitle !== undefined
      ? `${pageTitle} | ${metaData.title}`
      : `${metaData.title}（リグニオ）`;
  const description =
    pageDescription !== undefined ? pageDescription : metaData.description;
  const { author } = metaData;
  const url = metaData.domain + pageUrl;
  const imageUrl =
    metaData.domain + ogpImageUrl ||
    `${metaData.domain}/images/${ogpImageName}`;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={url} />
      <meta name="og:image" content={imageUrl} />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="630" />
      <meta name="og:site_name" content={title} />
      <meta name="og:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name='twitter:site' content={} /> */}
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};

export default SEOHeader;
